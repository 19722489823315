import { NavLink } from 'react-router-dom'
import { PATH } from '../../utils/routes'
import {
    Wrap,
    Title,
    Container,
    WrapText,
    SubTitle,
    Text,
    ButtonBack,
    TextBold,
    WrapUpdates,
    ButtonBack2,
    SubTitle1,
} from '../privacyPolicy/PrivacyPolicy.styles'

export const Imprint = () => {
    return (
        <Wrap>
            <Title>Imprint page</Title>
            <SubTitle1>UPDATE: MAY 18, 2022</SubTitle1>
            <NavLink to={PATH.MAIN}>
                <ButtonBack2 props="props">
                    <span>&#10094;</span>
                </ButtonBack2>
                <ButtonBack>
                    <span>&#10094;</span>
                    back
                </ButtonBack>
            </NavLink>
            <Container>
                <WrapUpdates>
                    <SubTitle>UPDATE: JAN 30, 2023</SubTitle>
                    <NavLink to={PATH.MAIN}>
                        <ButtonBack>
                            <span>&#10094;</span>
                            back
                        </ButtonBack>
                    </NavLink>
                </WrapUpdates>
                <WrapText>
                    <TextBold props="props">
                        Veranwortlich für den Inhalt der Webseite
                    </TextBold>
                    <Text>
                        Hanschitz & Höfinger Consulting GmbH
                        <br /> Tuchlauben 7a
                        <br /> 1010 Wien, Österreich
                        <br /> FB Gericht: Handelsgericht Wien Standort der
                        Gewerbeberechtigung: 1010 Wien
                    </Text>
                    <TextBold props="props">Kontakt</TextBold>
                    <Text>
                        Geschäftsführer: Herr Hanschitz Peter <br /> Telefon:
                        (auf schriftliche Anfrage) <br /> E-Mail:
                        ph@hanschitzhoefinger.com
                        <br /> Umsatzsteuer <br />{' '}
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a
                        Umsatzsteuergesetz: <br /> ATU 78519346
                    </Text>
                    <TextBold props="props">Streitschlichtung</TextBold>
                    <Text>
                        Die Europäische Kommission stellt eine Plattform zur
                        Online-Streitbeilegung (OS) bereit:
                        https://ec.europa.eu/consumers/odr. Unsere
                        E-Mail-Adresse finden Sie oben im Impressum.
                    </Text>
                    <Text>
                        {' '}
                        Wir sind nicht bereit oder verpflichtet, an
                        Streitbeilegungsverfahren vor einer
                        Verbraucherschlichtungsstelle teilzunehmen.
                    </Text>
                    <TextBold props="props">Haftung für Inhalte</TextBold>
                    <Text>
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                        eigene Inhalte auf diesen Seiten nach den allgemeinen
                        Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                        als Diensteanbieter jedoch nicht verpflichtet,
                        übermittelte oder gespeicherte fremde Informationen zu
                        überwachen oder nach Umständen zu forschen, die auf eine
                        rechtswidrige Tätigkeit hinweisen.
                    </Text>
                    <Text>
                        Verpflichtungen zur Entfernung oder Sperrung der Nutzung
                        von Informationen nach den allgemeinen Gesetzen bleiben
                        hiervon unberührt. Eine diesbezügliche Haftung ist
                        jedoch erst ab dem Zeitpunkt der Kenntnis einer
                        konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                        von entsprechenden Rechtsverletzungen werden wir diese
                        Inhalte umgehend entfernen.
                    </Text>
                    <TextBold props="props">Haftung für Links</TextBold>
                    <Text>
                        Unser Angebot enthält Links zu externen Websites
                        Dritter, auf deren Inhalte wir keinen Einfluss haben.
                        Deshalb können wir für diese fremden Inhalte auch keine
                        Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
                        ist stets der jeweilige Anbieter oder Betreiber der
                        Seiten verantwortlich. Die verlinkten Seiten wurden zum
                        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                        überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                        Verlinkung nicht erkennbar.
                    </Text>
                    <Text>
                        Eine permanente inhaltliche Kontrolle der verlinkten
                        Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Links umgehend
                        entfernen.
                    </Text>
                    <TextBold props="props">Urheberrecht</TextBold>
                    <Text>
                        Die durch die Seitenbetreiber erstellten Inhalte und
                        Werke auf diesen Seiten unterliegen dem deutschen
                        Urheberrecht. Die Vervielfältigung, Bearbeitung,
                        Verbreitung und jede Art der Verwertung außerhalb der
                        Grenzen des Urheberrechtes bedürfen der schriftlichen
                        Zustimmung des jeweiligen Autors bzw. Erstellers.
                        Downloads und Kopien dieser Seite sind nur für den
                        privaten, nicht kommerziellen Gebrauch gestattet.
                    </Text>
                    <Text>
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber
                        erstellt wurden, werden die Urheberrechte Dritter
                        beachtet. Insbesondere werden Inhalte Dritter als solche
                        gekennzeichnet. Sollten Sie trotzdem auf eine
                        Urheberrechtsverletzung aufmerksam werden, bitten wir um
                        einen entsprechenden Hinweis. Bei Bekanntwerden von
                        Rechtsverletzungen werden wir derartige Inhalte umgehend
                        entfernen.
                    </Text>
                </WrapText>
            </Container>
        </Wrap>
    )
}

import styled from 'styled-components'
import { images } from '../../styles/images'

export const CarouselContainer = styled.div`
    display: none;
    @media (max-width: 575px) {
        display: block;
    }
`
export const SectionStage = styled.div`
    height: 200px;
    width: 320px;
    position: relative;
    display: flex;
    margin: auto;
    overflow: hidden;
`
export const WrapDesc = styled.div`
    display: flex;
    align-items: center;
    margin: 20px auto;
`
export const Img = styled.img`
    width: 46px;
    height: 46px;
`
export const Title = styled.h3`
    margin: 0 15px;
    font-weight: 400;
    font-size: 40px;
    color: #ffffff;
`
export const WrapButtons = styled.div`
    position: absolute;
    left: 12px;
    top: -34px;
    width: 288px;
`
export const ButtonPrev = styled.button`
    position: absolute;
    top: 200px;
    width: 88px;
    height: 88px;
    background-image: url(${images.arrow_prev});
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s linear;
    left: 0;
`
export const ButtonNext = styled.button`
    position: absolute;
    top: 200px;
    width: 88px;
    height: 88px;
    background-image: url(${images.arrow_right});
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s linear;
    right: 0;
`
export const WrapDots = styled.div`
    position: absolute;
    left: 27%;
    top: 195px;
    display: flex;
    justify-content: space-evenly;
    width: 130px;
`
export const Dot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background: ${({ active }) => (active ? '#d9d9d9' : 'none')};
`
export const SubTitle = styled.h3`
    margin: 15px 0 0;
    font-weight: 100;
    font-size: 25px;
    color: #ffffff;
    span {
        font-weight: 700;
    }
`

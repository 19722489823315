import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CarouselStagesComponent } from '../carouselStages/CarouselStages.jsx'
import {
    ImgHexagons,
    About,
    ContainerText,
    Text,
    ImgIcon,
    Wrapper,
    ContainerAbout,
    WrapOverview,
    WrapOverviewItem,
    OverviewTitle,
    Divider,
    WrapHexagons,
    WrapBlockchain,
    WrapWeb,
    WrapNumbers,
    ContainerBlockchain,
    DescriptionBlockchain,
    ContainerWeb,
    SubTitle,
    ImgIconPlan,
    ImgIconBuild,
    ImgIconIterate,
    ImgIconLaunch,
    ImgIconMeasure,
    ButtonStartMobile,
    Plus,
    Description,
} from '../../pages/mainPage/MainPage.styles'

export const ServicesComponent = () => {
    const { t } = useTranslation()
    const [counterOn, setCounterOn] = useState(false)
    return (
        <>
            <Wrapper>
                <About>
                    <ContainerAbout>
                        <SubTitle>{t('services.title')}</SubTitle>
                    </ContainerAbout>
                    <ContainerText>
                        <Text>{t('services.text')}</Text>
                    </ContainerText>
                </About>
            </Wrapper>
            <WrapOverview>
                <SubTitle>{t('services.subtitle')}</SubTitle>
                <CarouselStagesComponent />
                <WrapOverviewItem>
                    <div>
                        <ImgIcon></ImgIcon>
                        <OverviewTitle>1.{t('services.1')}</OverviewTitle>
                    </div>
                    <Divider></Divider>
                    <div>
                        <ImgIconPlan></ImgIconPlan>
                        <OverviewTitle>2.{t('services.2')}</OverviewTitle>
                    </div>
                    <Divider></Divider>
                    <div>
                        <ImgIconBuild></ImgIconBuild>
                        <OverviewTitle>3.{t('services.3')}</OverviewTitle>
                    </div>
                    <Divider></Divider>
                    <div>
                        <ImgIconLaunch></ImgIconLaunch>
                        <OverviewTitle>4.{t('services.4')}</OverviewTitle>
                    </div>
                    <Divider></Divider>
                    <div>
                        <ImgIconMeasure></ImgIconMeasure>
                        <OverviewTitle>5.{t('services.5')}</OverviewTitle>
                    </div>
                    <Divider></Divider>
                    <div>
                        <ImgIconIterate></ImgIconIterate>
                        <OverviewTitle>6.{t('services.6')}</OverviewTitle>
                    </div>
                </WrapOverviewItem>
            </WrapOverview>
            <ButtonStartMobile props="props">
                {t('buttnos.start')}
                <span>&#10095;</span>
            </ButtonStartMobile>
            <WrapHexagons>
                <ImgHexagons></ImgHexagons>
                <ContainerBlockchain>
                    <WrapBlockchain>
                        <SubTitle>{t('services.blockchainTitle1')}</SubTitle>
                        <DescriptionBlockchain>
                            {t('services.blockchainText1')}
                        </DescriptionBlockchain>
                    </WrapBlockchain>
                </ContainerBlockchain>
                <ContainerWeb>
                    <WrapWeb>
                        <SubTitle>{t('services.blockchainTitle2')}</SubTitle>
                        <DescriptionBlockchain>
                            {t('services.blockchainText2')}
                        </DescriptionBlockchain>
                    </WrapWeb>
                </ContainerWeb>
            </WrapHexagons>
            <ScrollTrigger
                onEnter={() => setCounterOn(true)}
                onExit={() => setCounterOn(false)}
            >
                <WrapNumbers>
                    <div>
                        <Plus>
                            {counterOn && (
                                <CountUp
                                    start={0}
                                    end={9}
                                    duration={1}
                                    delay={0}
                                />
                            )}
                            +
                        </Plus>
                        <Description>{t('services.experience')}</Description>
                    </div>
                    <div>
                        <Plus>
                            {counterOn && (
                                <CountUp
                                    start={0}
                                    end={150}
                                    duration={1}
                                    delay={0}
                                />
                            )}
                            +
                        </Plus>
                        <Description>{t('services.projects')}</Description>
                    </div>
                    <div>
                        <Plus>
                            {counterOn && (
                                <CountUp
                                    start={0}
                                    end={6}
                                    duration={1}
                                    delay={0}
                                />
                            )}
                            +
                        </Plus>
                        <Description>{t('services.countries')}</Description>
                    </div>
                </WrapNumbers>
            </ScrollTrigger>
        </>
    )
}

import { useTranslation } from 'react-i18next'
import { AboutComponent } from '../../components/about/About.jsx'
import { ServicesComponent } from '../../components/services/Services.jsx'
import { TeamComponent } from '../../components/team/Team.jsx'
import { ContactComponent } from '../../components/contact/Contact.jsx'
import { images } from '../../styles/images.js'
import {
    Container,
    Wrap,
    Title,
    WrapRunText,
    ArrowDown,
    RunningText,
    Img,
    ButtonStartTablet,
} from './MainPage.styles'

export const MainPage = () => {
    const { t } = useTranslation()
    return (
        <Container>
            <Wrap>
                <Title>{t('mainTitle')}</Title>
                <WrapRunText>
                    <RunningText src={images.runtext}></RunningText>
                    <ArrowDown src={images.arrow_down}></ArrowDown>
                </WrapRunText>
                <ButtonStartTablet props="props">
                    {t('buttnos.start')}
                    <span>&#10095;</span>
                </ButtonStartTablet>
            </Wrap>
            <Img src={images.mainImg} />
            <AboutComponent />
            <ServicesComponent />
            <TeamComponent />
            <ContactComponent />
        </Container>
    )
}

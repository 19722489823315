import { NavLink } from 'react-router-dom'
import { PATH } from '../../utils/routes'
import {
    Wrap,
    Title,
    Container,
    WrapText,
    SubTitle,
    Text,
    ButtonBack,
    TextBold,
    WrapUpdates,
    SubTitle1,
    ButtonBack2,
} from './PrivacyPolicy.styles'

export const PrivacyPolicy = () => {
    return (
        <Wrap>
            <Title>Privacy Policy</Title>
            <SubTitle1>UPDATE: MAY 18, 2022</SubTitle1>
            <NavLink to={PATH.MAIN}>
                <ButtonBack2 props="props">
                    <span>&#10094;</span>
                </ButtonBack2>
                <ButtonBack>
                    <span>&#10094;</span>
                    back
                </ButtonBack>
            </NavLink>
            <Container>
                <WrapUpdates>
                    <SubTitle>UPDATE: MAY 18, 2022</SubTitle>
                    <NavLink to={PATH.MAIN}>
                        <ButtonBack props="props">
                            <span>&#10094;</span>
                            back
                        </ButtonBack>
                    </NavLink>
                </WrapUpdates>
                <WrapText>
                    <Text>
                        Notice About How We Use and Protect Personal Information
                        We inform you, in accordance with the provisions of
                        Spanish and EU data protection regulations, regarding
                        the treatment of your professional contact data, which
                        we will have in the case that you fill out any of our
                        contact forms, in our website or in third-party websites
                        that may collect such professional contact data for us.
                    </Text>
                    <Text>
                        In this regard, we, BEQUANT, S.L. (BEQUANT) are
                        responsible for the treatment of your contact data,
                        which are treated based on the legitimate interest of
                        our Company with the sole purpose of managing the
                        business relationship with our customers and potential
                        customers.{' '}
                    </Text>{' '}
                    <Text>
                        {' '}
                        Additionally, in order for us to send you commercial
                        communications about BEQUANT products and services, you
                        must give us your consent explicitly. To do this, we
                        request that you provide such consent by checking the
                        box that appears for this purpose in our contact forms,
                        in our website or in third-party websites that may
                        collect such professional contact data for us.
                    </Text>{' '}
                    <Text>
                        {' '}
                        In any case, we inform you that your personal data will
                        not be communicated to third parties, except where
                        appropriate and necessary for compliance with legal
                        obligations applicable to BEQUANT.{' '}
                    </Text>{' '}
                    <Text>
                        {' '}
                        The personal data collected will be kept for the
                        duration of the business relationship. Once said
                        business relationship is terminated, the data will be
                        kept blocked during the prescription periods established
                        by the applicable legislation. Furthermore, in the event
                        that you have accepted that we end you our commercial
                        communications, the data will be kept and processed for
                        that purpose until such time as the consent is revoked.
                    </Text>{' '}
                    <Text>
                        {' '}
                        Finally, we inform you that you may, at any time, revoke
                        your consent to us sending you our commercial
                        communications, as well as exercise your rights of
                        access, rectification, deletion and portability of your
                        data, of limitation and opposition to your treatment, as
                        well as not be the subject of decisions based solely on
                        the automated processing of your data, where
                        appropriate, before BEQUANT, by writing us to any of the
                        following addresses:
                    </Text>{' '}
                    <TextBold>By regular mail</TextBold>
                    <Text>
                        {' '}
                        Calle Raimundo Fernández Villaverde, 61 - 5th Floor,
                        Madrid, 28003, Madrid. Spain
                    </Text>{' '}
                    <TextBold> By e-mail</TextBold>
                    <Text props="props"> info@bequant.com</Text>{' '}
                    <Text>
                        {' '}
                        Finally, you are also informed of your right to file a
                        claim with the Agencia Española de Protección de Datos
                        in case you are aware or consider that there may be a
                        breach of the applicable data protection regulations.
                    </Text>
                </WrapText>
            </Container>
        </Wrap>
    )
}

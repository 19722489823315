import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Title } from '../navbar/Navbar.styles'
import { PATH } from '../../utils/routes.js'
import { images } from '../../styles/images'
import {
    handlerScrollUp,
    handlerScrollAbout,
    handlerScrollServices,
    handlerScrollTeam,
    handlerScrollNews,
} from '../../helpers/scroll'
import {
    Wrap,
    WrapFooter,
    Logo,
    WrapItems,
    WrapLinks,
    WrapRight,
    ButtonUp,
    CopyRight,
} from './Footer.styles'

export const Footer = () => {
    const { t } = useTranslation()
    return (
        <Wrap>
            <WrapFooter>
                <NavLink to={PATH.MAIN}>
                    <Logo src={images.logo} onClick={handlerScrollUp} />
                </NavLink>
                <WrapItems>
                    <WrapLinks>
                        <Title onClick={handlerScrollUp}>
                            <NavLink to={PATH.PRIVACY_POLICY}>
                                {t('navbar.policy')}
                            </NavLink>
                        </Title>
                        <Title onClick={handlerScrollUp}>
                            <NavLink to={PATH.TERMS}>
                                {t('navbar.terms')}
                            </NavLink>
                        </Title>
                        <Title onClick={handlerScrollUp}>
                            <NavLink to={PATH.IMPRINT}>
                                {' '}
                                {t('navbar.imprint')}
                            </NavLink>
                        </Title>
                    </WrapLinks>
                    <WrapLinks>
                        <Title onClick={handlerScrollAbout}>
                            {t('navbar.about')}
                        </Title>
                        <Title onClick={handlerScrollServices}>
                            {t('navbar.services')}
                        </Title>
                        <Title onClick={handlerScrollTeam}>
                            {t('navbar.ourTeam')}
                        </Title>
                        <Title onClick={handlerScrollNews}>
                            {' '}
                            {t('navbar.contact')}
                        </Title>
                    </WrapLinks>
                    <Title props="props">ph@hanschitzhoefinger.com</Title>
                </WrapItems>
            </WrapFooter>
            <WrapRight>
                <CopyRight>{t('navbar.copyRight')}</CopyRight>
                <ButtonUp
                    src={images.btn_arrow}
                    onClick={handlerScrollUp}
                ></ButtonUp>
            </WrapRight>
        </Wrap>
    )
}

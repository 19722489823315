import { useTranslation } from 'react-i18next'
import { CarouselTeamTablet } from '../carouselTeamTablet/CarouselTeam.jsx'
import { CarouselTeamMobile } from '../carouselTeamMobile/CarouselTeamMobile.jsx'
import { images } from '../../styles/images'
import {
    About,
    ContainerText,
    Text,
    ContainerAbout,
    WrapTeamImg,
    SubTitle,
    WrapImg,
    TeamImg,
    TeamImg2,
    WrapName,
    Description,
} from '../../pages/mainPage/MainPage.styles'

export const TeamComponent = () => {
    const { t } = useTranslation()
    return (
        <>
            <About>
                <ContainerAbout>
                    <SubTitle>{t('team.title')}</SubTitle>
                </ContainerAbout>
                <ContainerText>
                    <Text>{t('team.text')}</Text>
                </ContainerText>
            </About>
            <CarouselTeamTablet />
            <CarouselTeamMobile />
            <WrapTeamImg>
                <WrapImg>
                    <TeamImg src={images.team_peter}></TeamImg>
                    <WrapName>
                        <SubTitle props="props">Peter Hanschitz</SubTitle>
                        <Description props="props">FOUNDER</Description>
                    </WrapName>
                </WrapImg>
                <WrapImg>
                    <TeamImg2 src={images.team_stefan}></TeamImg2>
                    <WrapName>
                        <SubTitle props="props">Stefan Höfinger</SubTitle>
                        <Description props="props">CO-FOUNDER</Description>
                    </WrapName>
                </WrapImg>
                <WrapImg>
                    <TeamImg src={images.team_business}></TeamImg>
                    <WrapName>
                        <SubTitle props="props">PAUL WAGNER</SubTitle>
                        <Description props="props">
                            Software Development
                        </Description>
                    </WrapName>
                </WrapImg>
            </WrapTeamImg>
        </>
    )
}

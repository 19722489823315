import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { images } from '../../styles/images'
import './Carousel.css'
import {
    CarouselContainer,
    Section,
    WrapImg,
    Gradient,
    Img,
    WrapButtons,
    WrapDots,
    Dot,
    ButtonNext,
    ButtonPrev,
    Text,
    Title,
    WrapDesc,
} from './Carousel.styles'

export const CarouselComponent = () => {
    const { t } = useTranslation()
    const cards = [
        {
            id: 1,
            image: images.about_1,
            title: t('about.question1'),
            desc: t('about.answer1'),
        },
        {
            id: 2,
            image: images.about_2,
            title: t('about.question2'),
            desc: t('about.answer2'),
        },
        {
            id: 3,
            image: images.about_3,
            title: t('about.question3'),
            desc: t('about.answer3'),
        },
    ]
    const [currentIndex, setCurrentIndex] = useState(0)
    useEffect(() => {
        const lastIndex = cards.length - 1
        if (currentIndex < 0) {
            setCurrentIndex(lastIndex)
        }
        if (currentIndex > lastIndex) {
            setCurrentIndex(0)
        }
    }, [currentIndex])

    useEffect(() => {
        let slider = setInterval(
            () => setCurrentIndex((prevState) => prevState + 1),
            5000
        )
        return () => {
            clearInterval(slider)
        }
    }, [currentIndex])

    const Dots = []
    for (let i = 0; i < 3; i++) {
        Dots.push(<Dot key={i} itemNumber={i} active={i == currentIndex} />)
    }

    return (
        <CarouselContainer>
            <Section>
                {cards.map((card, cardIndex) => {
                    const { id, image, title, desc } = card

                    let position = 'nextSlide'
                    if (cardIndex === currentIndex) {
                        position = 'activeSlide'
                    }
                    if (
                        cardIndex === currentIndex - 1 ||
                        (currentIndex === 0 && cardIndex === cards.length - 1)
                    ) {
                        position = 'lastSlide'
                    }

                    return (
                        <article className={position} key={id}>
                            <WrapImg>
                                <Gradient></Gradient>
                                <Img src={image} />
                            </WrapImg>
                            <WrapDesc>
                                <Title>{title}</Title>
                                <Text>{desc}</Text>
                            </WrapDesc>
                        </article>
                    )
                })}
                <WrapButtons>
                    <ButtonPrev
                        onClick={() =>
                            setCurrentIndex((prevState) => prevState - 1)
                        }
                    ></ButtonPrev>
                    <WrapDots>{Dots}</WrapDots>
                    <ButtonNext
                        onClick={() =>
                            setCurrentIndex((prevState) => prevState + 1)
                        }
                    ></ButtonNext>
                </WrapButtons>
            </Section>
        </CarouselContainer>
    )
}

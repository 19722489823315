export const handlerScrollUp = () => {
    window.scrollTo(0, 0)
}

export const handlerScrollAbout = () => {
    window.scrollTo({
        top: 1000,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollServices = () => {
    window.scrollTo({
        top: 1850,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollTeam = () => {
    window.scrollTo({
        top: 4000,
        left: 0,
        behavior: 'smooth',
    })
}

export const handlerScrollNews = () => {
    window.scrollTo({
        top: 4800,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollAboutTablet = () => {
    window.scrollTo({
        top: 700,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollServicesTablet = () => {
    window.scrollTo({
        top: 1250,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollTeamTablet = () => {
    window.scrollTo({
        top: 2850,
        left: 0,
        behavior: 'smooth',
    })
}

export const handlerScrollNewsTablet = () => {
    window.scrollTo({
        top: 3400,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollAboutMobile = () => {
    window.scrollTo({
        top: 450,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollServicesMobile = () => {
    window.scrollTo({
        top: 700,
        left: 0,
        behavior: 'smooth',
    })
}
export const handlerScrollTeamMobile = () => {
    window.scrollTo({
        top: 3040,
        left: 0,
        behavior: 'smooth',
    })
}

export const handlerScrollNewsMobile = () => {
    window.scrollTo({
        top: 3700,
        left: 0,
        behavior: 'smooth',
    })
}

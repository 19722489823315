import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { images } from '../../styles/images'
import {
    CarouselContainer,
    SectionStage,
    Img,
    WrapButtons,
    WrapDots,
    Dot,
    ButtonNext,
    ButtonPrev,
    WrapDesc,
    SubTitle,
    Title,
} from './CarouselStages.styles'
import '../carouselTablet/Carousel.css'

export const CarouselStagesComponent = () => {
    const { t } = useTranslation()
    const cards = [
        {
            id: 1,
            image: images.define,
            title: t('services.1'),
        },
        {
            id: 2,
            image: images.plan,
            title: t('services.2'),
        },
        {
            id: 3,
            image: images.build,
            title: t('services.3'),
        },
        {
            id: 4,
            image: images.launch,
            title: t('services.4'),
        },
        {
            id: 5,
            image: images.measure,
            title: t('services.5'),
        },
        {
            id: 6,
            image: images.iterate,
            title: t('services.6'),
        },
    ]
    const [currentIndex, setCurrentIndex] = useState(0)
    useEffect(() => {
        const lastIndex = cards.length - 1
        if (currentIndex < 0) {
            setCurrentIndex(lastIndex)
        }
        if (currentIndex > lastIndex) {
            setCurrentIndex(0)
        }
    }, [currentIndex])

    const Dots = []
    for (let i = 0; i < 6; i++) {
        Dots.push(<Dot key={i} itemNumber={i} active={i == currentIndex} />)
    }

    return (
        <CarouselContainer>
            <SectionStage>
                {cards.map((card, cardIndex) => {
                    const { id, image, title } = card

                    let position = 'nextSlide'
                    if (cardIndex === currentIndex) {
                        position = 'activeSlide'
                    }
                    if (
                        cardIndex === currentIndex - 1 ||
                        (currentIndex === 0 && cardIndex === cards.length - 1)
                    ) {
                        position = 'lastSlide'
                    }

                    return (
                        <section className={position} key={id}>
                            <SubTitle>
                                STEP<span>0{id}</span>
                            </SubTitle>
                            <WrapDesc>
                                <Img src={image} />
                                <Title>{title}</Title>
                            </WrapDesc>
                        </section>
                    )
                })}
                <WrapButtons>
                    <ButtonPrev
                        onClick={() =>
                            setCurrentIndex((prevState) => prevState - 1)
                        }
                    ></ButtonPrev>
                    <WrapDots>{Dots}</WrapDots>
                    <ButtonNext
                        onClick={() =>
                            setCurrentIndex((prevState) => prevState + 1)
                        }
                    ></ButtonNext>
                </WrapButtons>
            </SectionStage>
        </CarouselContainer>
    )
}

import { NavLink } from 'react-router-dom'
import { useState } from 'react'
import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next'
import {
    handlerScrollAbout,
    handlerScrollServices,
    handlerScrollTeam,
    handlerScrollNews,
    handlerScrollAboutTablet,
    handlerScrollServicesTablet,
    handlerScrollTeamTablet,
    handlerScrollNewsTablet,
    handlerScrollAboutMobile,
    handlerScrollServicesMobile,
    handlerScrollTeamMobile,
    handlerScrollNewsMobile,
} from '../../helpers/scroll'
import { PATH } from '../../utils/routes'
import { images } from '../../styles/images'
import {
    Wrap,
    Title,
    Logo,
    WrapPages,
    WrapButtons,
    DropDown,
    ButtonLanguage,
    DropDownContent,
    ButtonStart,
    StyledWrapWithBurger,
    Hamburger,
    Menu,
    WrapWithBurger,
    ImgLine,
    WrapperLinks,
    WrapLink,
    WrapLang,
    WrapPagesTablet,
    WrapPagesMobile,
} from './Navbar.styles'

export const Navbar = () => {
    const languages = [
        {
            name: 'en',
            code: 'en',
        },
        {
            name: 'de',
            code: 'de',
        },
        {
            name: 'ru',
            code: 'ru',
        },
    ]
    const { t } = useTranslation()
    const [active, setActive] = useState(null)
    const [selected, setIsSelected] = useState('EN')
    const [openBurger, setOpenBurger] = useState(true)

    const changeLanguages = (e, code) => {
        setIsSelected(e.target.textContent)
        setActive(code)
        return changeLanguage(code)
    }
    const changeOpenBurger = () => {
        setOpenBurger(!openBurger)
    }

    return (
        <StyledWrapWithBurger>
            <WrapWithBurger>
                <Title props="props">
                    <NavLink to={PATH.MAIN}>
                        <Logo src={images.logo} />
                    </NavLink>
                </Title>
                <Hamburger openBurger={openBurger} onClick={changeOpenBurger}>
                    <Menu></Menu>
                    <Menu></Menu>
                    <Menu></Menu>
                </Hamburger>
            </WrapWithBurger>
            <Wrap openBurger={openBurger}>
                <Title props="props" onClick={() => setOpenBurger(!openBurger)}>
                    <NavLink to={PATH.MAIN}>
                        <Logo src={images.logo} />
                    </NavLink>
                </Title>
                {!openBurger && (
                    <WrapLang>
                        {languages.map(({ name, code }) => {
                            return (
                                <ButtonLanguage
                                    key={code}
                                    isActive={active === code}
                                    onClick={(event) =>
                                        changeLanguages(event, code)
                                    }
                                >
                                    <span>{name}</span>
                                </ButtonLanguage>
                            )
                        })}
                    </WrapLang>
                )}
                {!openBurger && (
                    <WrapLink>
                        <ImgLine src={images.line} />
                        <WrapperLinks>
                            <Title onClick={() => setOpenBurger(!openBurger)}>
                                <NavLink to={PATH.PRIVACY_POLICY}>
                                    {t('navbar.policy')}
                                </NavLink>
                            </Title>
                            <Title onClick={() => setOpenBurger(!openBurger)}>
                                <NavLink to={PATH.TERMS}>
                                    {t('navbar.terms')}
                                </NavLink>
                            </Title>
                            <Title onClick={() => setOpenBurger(!openBurger)}>
                                <NavLink to={PATH.IMPRINT}>
                                    {t('navbar.imprint')}
                                </NavLink>
                            </Title>
                        </WrapperLinks>
                    </WrapLink>
                )}
                <div style={{ display: 'flex' }}>
                    <WrapPages>
                        <Title onClick={handlerScrollAbout}>
                            {t('navbar.about')}
                        </Title>
                        <Title onClick={handlerScrollServices}>
                            {t('navbar.services')}
                        </Title>
                        <Title onClick={handlerScrollTeam}>
                            {t('navbar.ourTeam')}
                        </Title>
                        <Title onClick={handlerScrollNews}>
                            {t('navbar.contact')}
                        </Title>
                    </WrapPages>
                    <WrapPagesTablet onClick={() => setOpenBurger(!openBurger)}>
                        <Title onClick={handlerScrollAboutTablet}>
                            {t('navbar.about')}
                        </Title>
                        <Title onClick={handlerScrollServicesTablet}>
                            {t('navbar.services')}
                        </Title>
                        <Title onClick={handlerScrollTeamTablet}>
                            {t('navbar.ourTeam')}
                        </Title>
                        <Title onClick={handlerScrollNewsTablet}>
                            {t('navbar.contact')}
                        </Title>
                    </WrapPagesTablet>
                    <WrapPagesMobile onClick={() => setOpenBurger(!openBurger)}>
                        <Title onClick={handlerScrollAboutMobile}>
                            {t('navbar.about')}
                        </Title>
                        <Title onClick={handlerScrollServicesMobile}>
                            {t('navbar.services')}
                        </Title>
                        <Title onClick={handlerScrollTeamMobile}>
                            {t('navbar.ourTeam')}
                        </Title>
                        <Title onClick={handlerScrollNewsMobile}>
                            {' '}
                            {t('navbar.contact')}
                        </Title>
                    </WrapPagesMobile>
                    <WrapButtons>
                        <DropDown>
                            <ButtonLanguage>{selected}</ButtonLanguage>
                            <div>
                                <DropDownContent>
                                    {languages.map(({ name }) => {
                                        return (
                                            <button
                                                key={name}
                                                onClick={(event) =>
                                                    changeLanguages(event, name)
                                                }
                                            >
                                                {name}
                                            </button>
                                        )
                                    })}
                                </DropDownContent>
                            </div>
                        </DropDown>
                        <ButtonStart>
                            {t('buttnos.start')}
                            <span>&#10095;</span>
                        </ButtonStart>
                    </WrapButtons>
                </div>
            </Wrap>
        </StyledWrapWithBurger>
    )
}

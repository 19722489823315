import { useTranslation } from 'react-i18next'
import { ButtonStart } from '../navbar/Navbar.styles'
import {
    WrapMap,
    ContainerMap,
    MapImg,
    SubTitleMap,
    Plus,
    ImgCircle,
    ImgCircleSwit,
    ButtonStartMobile,
    ButtonStartTablet,
} from '../../pages/mainPage/MainPage.styles'

export const ContactComponent = () => {
    const { t } = useTranslation()
    return (
        <WrapMap>
            <ButtonStartMobile>
                {t('buttnos.start')}
                <span>&#10095;</span>
            </ButtonStartMobile>
            <ContainerMap>
                <MapImg></MapImg>
                <ImgCircleSwit></ImgCircleSwit>
                <ImgCircle></ImgCircle>
            </ContainerMap>
            <div>
                <Plus props="props">{t('contactTitle')}</Plus>
                <SubTitleMap>{t('contactText')}</SubTitleMap>
                <ButtonStart props="props">
                    {t('buttnos.start')}
                    <span>&#10095;</span>
                </ButtonStart>
                <ButtonStartTablet>
                    {t('buttnos.start')}
                    <span>&#10095;</span>
                </ButtonStartTablet>
            </div>
        </WrapMap>
    )
}

import styled from 'styled-components'

export const Wrap = styled.div`
    padding: 80px 50px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    @media (max-width: 1024px) {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 30px 0;
    }
`
export const Logo = styled.img`
    @media (max-width: 1024px) {
        width: 150px;
    }
`
export const WrapFooter = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`
export const WrapItems = styled.div`
    display: flex;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const WrapLinks = styled.ul`
    margin-right: 30px;
    margin-top: 0;
    a {
        text-decoration: none;
        color: #d2d2d2;
    }
`
export const WrapRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const CopyRight = styled.p`
    font-size: 13px;
    color: rgba(153, 153, 153, 0.5);
    text-transform: uppercase;
`
export const ButtonUp = styled.img`
    cursor: pointer;
    @media (max-width: 1024px) {
        display: none;
    }
`

import styled from 'styled-components'
import { images } from '../../styles/images'

export const Container = styled.div`
    padding: 250px 50px 0;
    @media (max-width: 1024px) {
        padding: 150px 30px 0;
    }
    @media (max-width: 575px) {
        padding: 150px 20px 0;
    }
`
export const Wrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    @media (max-width: 575px) {
        margin-bottom: 40px;
    }
`
export const Title = styled.h1`
    max-width: 560px;
    margin: 0;
    font-weight: 400;
    font-size: 70px;
    line-height: 80px;
    text-transform: uppercase;
    color: #ffffff;
    @media (max-width: 1024px) {
        max-width: 400px;
        font-size: 50px;
        line-height: 70px;
    }
    @media (max-width: 575px) {
        font-size: 47px;
        line-height: normal;
    }
`
export const WrapRunText = styled.div`
    position: relative;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const ArrowDown = styled.img`
    position: absolute;
    left: 45%;
    top: 25%;
`
export const RunningText = styled.img`
    animation: 6s linear 0s normal none infinite running RunningText;
    -webkit-animation: 6s linear 0s normal none infinite running RunningText;
    @keyframes RunningText {
        0% {
            transform: rotate(360deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
`
export const ButtonStartTablet = styled.button`
    display: none;
    @media (max-width: 1024px) {
        display: block;
        width: 287px;
        min-width: 200px;
        height: 46px;
        margin-top: ${({ props }) => (props ? '150px' : '30px')};
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        background: #ffffff;
        border-radius: 30px;
        border: none;
        color: #000000;
        cursor: pointer;
        span {
            padding-left: 20px;
        }
    }
    @media (max-width: 575px) {
        display: none;
    }
`
export const ButtonStartMobile = styled.button`
    display: none;
    @media (max-width: 575px) {
        display: block;
        width: 287px;
        height: 46px;
        margin: 50px auto;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        background: #ffffff;
        border-radius: 30px;
        border: none;
        color: #000000;
        cursor: pointer;
        span {
            padding-left: 20px;
        }
    }
`
export const Img = styled.img`
    width: 100%;
    height: 100%;
    @media (max-width: 1024px) {
        min-height: 250px;
    }
    @media (max-width: 575px) {
        min-height: 180px;
    }
`
export const About = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 100px 0;
    @media (max-width: 575px) {
        flex-direction: column;
        margin: 40px 0;
    }
`
export const Questions = styled.div`
    position: relative;
    display: flex;
    justify-content: space-evenly;
`
export const QuestionBg = styled.div`
    position: absolute;
    height: 400px;
    width: 420px;
    @media (max-width: 1280px) {
        width: 330px;
        height: 300px;
    }
`
export const ImgAbout = styled.img`
    width: 100%;
    height: 100%;
`
export const QuestionText = styled.div`
    position: absolute;
    max-width: 320px;
    margin: 150px 60px;
    font-weight: 700;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
    text-shadow: 5px -2px 5px #000000;
    transition: all 600ms ease-in;
    transform: scale(1);
    @media (max-width: 1280px) {
        max-width: 278px;
        margin: 100px 30px;
        font-weight: 700;
        font-size: 24px;
    }
`
export const AnswerText = styled.div`
    max-width: 390px;
    margin: 110px 20px;
    text-align: center;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    font-weight: 400;
    font-size: 22px;
    color: #ffffff;
    transition: all 600ms ease-out;
    transform: scale(0);
    @media (max-width: 1280px) {
        max-width: 290px;
        margin: 70px 20px;
        font-size: 18px;
    }
`
export const Question = styled.div`
    margin-top: 100px;
    width: 430px;
    height: 400px;
    margin-right: 20px;
    :hover ${QuestionBg} {
        opacity: 0.5;
    }
    :hover ${QuestionText} {
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        transition: all 600ms ease-out;
    }
    :hover ${AnswerText} {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        transition: all 600ms ease-in;
    }
    @media (max-width: 1280px) {
        margin-top: 0;
    }
    @media (max-width: 1024px) {
        display: none;
    }
`
export const SubTitle = styled.h6`
    margin: 10px 0;
    font-weight: 700;
    font-size: 26px;
    color: #ffffff;
    text-transform: ${({ props }) => (props ? 'none' : 'uppercase')};
    @media (max-width: 1024px) {
        font-size: 18px;
        font-weight: 600;
    }
`
export const SubTitleMap = styled(SubTitle)`
    @media (max-width: 1024px) {
        width: 210px;
        font-weight: 400;
        font-size: 24px;
    }
    @media (max-width: 575px) {
        width: 230px;
        padding-left: 25px;
        font-size: 18px;
    }
`
export const ContainerText = styled.div`
    width: 65%;
    @media (max-width: 575px) {
        width: 100%;
    }
`
export const Text = styled.p`
    margin: 10px 0;
    font-weight: 400;
    font-size: 22px;
    color: #999999;
    @media (max-width: 1024px) {
        font-size: 14px;
    }
`
export const WrapAboutImg = styled.div`
    position: relative;
    display: flex;
    justify-content: space-evenly;
    height: 400px;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const TextInside = styled(Text)`
    text-align: center;
    width: 90%;
    padding: 0 0 0 20px;
    color: #ffffff;
    visibility: hidden;
    transition: 1s;
    z-index: 10;
    opacity: 0;
    transform: translateY(-200px);
    @media (max-width: 1280px) {
        transform: translateY(-150px);
    }
`
export const AboutText = styled.p`
    margin: 0 20px 20px;
    font-weight: 700;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
    z-index: 10;
    text-shadow: 1px 3px 5px #000000;
    transition: 1s;
    transform: translateY(0px);
`
export const AboutImg = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    :hover {
        opacity: 0.4;
    }
    :hover + ${AboutText} {
        opacity: 1;
        transition: 1s;
        transform: translateY(-100px);
    }
    @media (max-width: 1280px) {
        :hover + ${AboutText} {
            opacity: 1;
            transition: 1s;
            transform: translateY(-120px);
        }
    }
`
export const WrapImg = styled.div`
    position: relative;
    width: 430px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    :hover + ${TextInside} {
        visibility: visible;
        opacity: 1;
        transition: 1s;
        transform: translateY(-270px);
    }
    @media (max-width: 1280px) {
        width: 350px;
        height: 340px;
    }
`
export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 150px 0 50px;
    @media (max-width: 1280px) {
        margin: 0px 0 50px;
    }
    @media (max-width: 575px) {
        margin-bottom: 40px;
    }
`
export const ContainerAbout = styled.div`
    width: 270px;
    @media (max-width: 1024px) {
        width: 170px;
    }
`
export const Description = styled.p`
    margin: 0;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: ${({ props }) => (props ? '#FFFFFF' : '#999999')};
    @media (max-width: 1024px) {
        font-size: 14px;
    }
    @media (max-width: 575px) {
        padding: 10px 0;
    }
`
export const WrapOverview = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const OverviewTitle = styled(SubTitle)`
    font-weight: 500;
    font-size: 20px;
    margin-top: 18px;
    text-transform: none;
    @media (max-width: 1024px) {
        font-size: 14px;
    }
    @media (max-width: 850px) {
        font-size: 12px;
    }
`
export const WrapOverviewItem = styled.div`
    display: flex;
    align-items: flex-end;
    margin: 100px 0;
    div {
        :hover + ${OverviewTitle} {
            text-shadow: 1px 1px 5px #ffffff;
        }
    }
    @media (max-width: 1024px) {
        margin: 50px 0 0 0;
    }
    @media (max-width: 575px) {
        display: none;
    }
`
export const ImgIcon = styled.div`
    height: 70px;
    transition: 0.5s;
    opacity: 0.7;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${images.define});
    :hover {
        opacity: 1;
        transform: scale(1.2);
    }
`
export const ImgIconPlan = styled(ImgIcon)`
    background-image: url(${images.plan});
`
export const ImgIconBuild = styled(ImgIcon)`
    background-image: url(${images.build});
`
export const ImgIconLaunch = styled(ImgIcon)`
    background-image: url(${images.launch});
`
export const ImgIconMeasure = styled(ImgIcon)`
    background-image: url(${images.measure});
`
export const ImgIconIterate = styled(ImgIcon)`
    background-image: url(${images.iterate});
`
export const Divider = styled.div`
    width: 80px;
    margin: 0 20px 20px;
    border: 1px solid #404040;
    @media (max-width: 1024px) {
        width: 40px;
        margin: 0 15px 15px;
    }
    @media (max-width: 820px) {
        width: 30px;
        margin: 0 10px 15px;
    }
`
export const WrapHexagons = styled.div`
    position: relative;
    min-height: 900px;
    margin: 100px 0;
    @media (max-width: 1024px) {
        min-height: 620px;
        margin: 80px 0;
    }
    @media (max-width: 575px) {
        min-height: 680px;
    }
`
export const ImgHexagons = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${images.hexagons});
    @media (max-width: 1024px) {
        background-size: auto;
        background-image: url(${images.hexagons_tablet});
    }
    @media (max-width: 575px) {
        background-image: url(${images.hexagons_mobile});
    }
`
export const WrapBlockchain = styled.div`
    width: 31%;
    @media (max-width: 1024px) {
        width: 50%;
    }
    @media (max-width: 768px) {
        width: 40%;
    }
    @media (max-width: 575px) {
        width: 75%;
    }
`
export const ContainerBlockchain = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    @media (max-width: 1280px) {
        padding-top: 0px;
    }
    @media (max-width: 1024px) {
        padding-top: 85px;
    }
`
export const ContainerWeb = styled.div`
    padding-top: 70px;
    @media (max-width: 1024px) {
        padding-top: 200px;
    }
    @media (max-width: 768px) {
        padding-top: 110px;
    }
    @media (max-width: 575px) {
        padding-top: 80px;
    }
    @media (max-width: 375px) {
        padding-top: 60px;
    }
`
export const WrapWeb = styled.div`
    width: 430px;
    @media (max-width: 1280px) {
        width: 380px;
    }
    @media (max-width: 1024px) {
        width: 330px;
    }
    @media (max-width: 768px) {
        width: 300px;
    }
    @media (max-width: 575px) {
        width: 280px;
    }
`
export const DescriptionBlockchain = styled.p`
    font-size: 20px;
    color: #999999;
    @media (max-width: 1024px) {
        font-size: 14px;
    }
    @media (max-width: 575px) {
        font-size: 12px;
    }
`
export const WrapNumbers = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 30px 0;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 19px;
    @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
    }
`
export const Plus = styled(Title)`
    font-weight: 700;
    font-size: 50px;
    text-align: ${({ props }) => (props ? 'start' : 'center')};
    @media (max-width: 1024px) {
        font-weight: ${({ props }) => (props ? '500' : '700')};
        font-size: 38px;
        line-height: ${({ props }) => (props ? '47px' : '60px')};
    }
    @media (max-width: 575px) {
        padding-left: ${({ props }) => (props ? '25px' : '0px')};
        font-size: ${({ props }) => (props ? '30px' : '50px')};
    }
`
export const WrapTeamImg = styled.div`
    position: relative;
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const WrapMap = styled.div`
    display: flex;
    align-items: center;
    margin: 220px 0;
    p {
        margin: 0 50px 50px 0;
        font-size: 30px;
    }
    @media (max-width: 1024px) {
        margin: 90px 0;
    }
    @media (max-width: 840px) {
        margin: 60px 0;
    }
    @media (max-width: 575px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin: 60px auto;
    }
`
export const ContainerMap = styled.div`
    position: relative;
    @media (max-width: 1024px) {
        margin-right: 20px;
    }
    @media (max-width: 1024px) {
        margin-right: 0px;
    }
`
export const MapImg = styled.div`
    width: 687px;
    height: 549px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${images.map});
    @media (max-width: 1024px) {
        width: 350px;
        height: 280px;
        margin-right: 50px;
        background-image: url(${images.map_tablet});
    }
    @media (max-width: 830px) {
        margin-right: 0;
    }
    @media (max-width: 575px) {
        width: 370px;
        height: 400px;
        margin-top: -80px;
        background-image: url(${images.map_mobile});
    }
`
export const WrapName = styled.div`
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 250px;
    z-index: 10;
    transition: 1s;
    transform: translateY(30px);
`
export const TeamImg = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    :hover {
        opacity: 1;
    }
    :hover + ${WrapName} {
        opacity: 1;
        transition: 1s;
        transform: translateY(0px);
    }
`
export const TeamImg2 = styled(TeamImg)`
    opacity: 0.5;
    :hover {
        opacity: 0.75;
    }
`
export const ImgCircle = styled.div`
    position: absolute;
    top: 64%;
    left: 44%;
    display: block;
    height: 12px;
    width: 12px;
    background-repeat: no-repeat;
    background-image: url(${images.dot});
    :hover {
        top: 49%;
        left: 34%;
        width: 115px;
        height: 105px;
        background-image: url(${images.austria});
    }
    @media (max-width: 1024px) {
        display: none;
    }
`
export const ImgCircleSwit = styled(ImgCircle)`
    position: absolute;
    top: 62%;
    left: 32%;
    display: block;
    :hover {
        top: 50%;
        left: 24%;
        width: 112px;
        height: 90px;
        background-image: url(${images.switzerland});
    }
    @media (max-width: 1024px) {
        display: none;
    }
`

import { useTranslation } from 'react-i18next'
import { images } from '../../styles/images'
import { CarouselComponent } from '../carouselTablet/Carousel.jsx'
import { CarouselMobileComponent } from '../carouselMobile/CarouselMobile.jsx'
import {
    About,
    SubTitle,
    ContainerText,
    Text,
    Questions,
    Question,
    QuestionBg,
    ImgAbout,
    QuestionText,
    AnswerText,
} from '../../pages/mainPage/MainPage.styles'

export const AboutComponent = () => {
    const { t } = useTranslation()
    const questions = [
        {
            img: images.about_1,
            question: t('about.question1'),
            answer: t('about.answer1'),
        },
        {
            img: images.about_2,
            question: t('about.question2'),
            answer: t('about.answer2'),
        },
        {
            img: images.about_3,
            question: t('about.question3'),
            answer: t('about.answer3'),
        },
    ]
    return (
        <>
            <About>
                <SubTitle>{t('about.aboutTitle')}</SubTitle>
                <ContainerText>
                    <Text>{t('about.aboutText')}</Text>
                </ContainerText>
            </About>
            <Questions>
                {questions.map((question) => (
                    <Question key={question.question}>
                        <QuestionBg>
                            <ImgAbout src={question.img} alt="" />
                        </QuestionBg>
                        <QuestionText>{question.question}</QuestionText>
                        <AnswerText>{question.answer}</AnswerText>
                    </Question>
                ))}
            </Questions>
            <CarouselComponent />
            <CarouselMobileComponent />
        </>
    )
}

import styled from 'styled-components'
import { images } from '../../styles/images'

export const CarouselContainer = styled.div`
    display: none;
    @media (max-width: 1024px) {
        height: 200px;
        display: block;
    }
    @media (max-width: 575px) {
        display: none;
    }
`
export const Section = styled.div`
    height: 450px;
    max-width: 800px;
    position: relative;
    display: flex;
    overflow: hidden;
`
export const WrapImg = styled.div`
    position: relative;
`
export const Gradient = styled.div`
    position: absolute;
    top: -130px;
    left: 90px;
    z-index: 10;
    width: 200px;
    height: 460px;
    transform: rotate(-90deg);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.5%, #000000 74.15%);
`
export const Img = styled.img`
    width: 350px;
    height: 200px;
    object-fit: cover;
    opacity: 0.8;
`
export const WrapDesc = styled.div`
    width: 320px;
    margin-top: ${({ props }) => (props ? '50px' : '0')};
    z-index: 100;
    text-align: center;
`
export const Title = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
`
export const Text = styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
`
export const WrapButtons = styled.div`
    position: absolute;
    left: 60%;
    top: -15px;
    width: 330px;
    @media (max-width: 850px) {
        left: 58%;
    }
    @media (max-width: 768px) {
        width: 270px;
    }
`
export const ButtonPrev = styled.button`
    position: absolute;
    top: 200px;
    width: 88px;
    height: 88px;
    background-image: url(${images.arrow_prev});
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s linear;
    left: 0;
`
export const ButtonNext = styled.button`
    position: absolute;
    top: 200px;
    width: 88px;
    height: 88px;
    background-image: url(${images.arrow_right});
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s linear;
    right: 0;
`
export const WrapDots = styled.div`
    position: absolute;
    left: 40%;
    top: 200px;
    display: flex;
    justify-content: space-evenly;
    width: 60px;
`
export const Dot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background: ${({ active }) => (active ? '#d9d9d9' : 'none')};
`
export const SubTitle = styled.h3`
    margin: 0;
    font-weight: 700;
    font-size: 26px;
    line-height: 45px;
    color: #ffffff;
`
export const Description = styled.p`
    margin: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #ffffff;
`

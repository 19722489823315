import styled from 'styled-components'
import { ButtonStart } from '../../components/navbar/Navbar.styles'

export const Wrap = styled.div`
    padding: 110px 50px 0;
    @media (max-width: 1024px) {
        padding: 200px 30px 0;
    }
    @media (max-width: 575px) {
        padding: 150px 20px 0;
    }
`
export const Title = styled.h4`
    margin: 0;
    padding-bottom: 50px;
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
    @media (max-width: 650px) {
        text-align: end;
        padding-bottom: 10px;
    }
`
export const SubTitle1 = styled.h6`
    display: none;
    @media (max-width: 650px) {
        display: block;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        text-align: end;
        margin: 0;
    }
`
export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    padding: 100px 0 80px;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    @media (max-width: 650px) {
        flex-direction: column-reverse;
        border-top: none;
        padding: 0 0 30px 0;
        margin-top: 50px;
    }
`
export const WrapText = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    @media (max-width: 650px) {
        width: 100%;
    }
`
export const SubTitle = styled.h6`
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    @media (max-width: 650px) {
        display: none;
    }
`
export const Text = styled.span`
    margin-bottom: 30px;
    font-weight: 400;
    font-style: ${({ props }) => (props ? 'italic' : 'normal')};
    font-size: 22px;
    line-height: 26px;
    color: #999999;
    @media (max-width: 650px) {
        font-size: 16px;
        line-height: 21px;
    }
`
export const TextBold = styled.span`
    margin-bottom: ${({ props }) => (props ? '20px' : '0')};
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #ffffff;
    @media (max-width: 650px) {
        font-size: 16px;
        line-height: 21px;
    }
`
export const ArrowLeft = styled.img`
    padding-right: 20px;
`
export const ButtonBack = styled(ButtonStart)`
    display: block;
    width: 112px;
    height: 46px;
    color: #ffffff;
    background: #000000;
    border-radius: 30px;
    border: 1px solid #ffffff;
    border: 1px solid #ffffff;
    span {
        padding-right: 10px;
    }
    :hover {
        background: #ffffff;
        color: #000000;
    }
    @media (max-width: 650px) {
        display: ${({ props }) => (props ? 'block' : 'none')};
    }
`
export const ButtonBack2 = styled(ButtonBack)`
    display: none;
    @media (max-width: 650px) {
        display: block;
        width: 45px;
        margin-top: -50px;
        color: #ffffff;
        span {
            padding-right: 10px;
        }
        :hover {
            background: #000000;
            color: #ffffff;
        }
    }
`
export const WrapUpdates = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

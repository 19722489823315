import { Routes, Route } from 'react-router-dom'
import { MainPage } from './pages/mainPage/MainPage.jsx'
import { Navbar } from './components/navbar/Navbar.jsx'
import { Footer } from './components/footer/Footer.jsx'
import { Terms } from './pages/terms/Terms.jsx'
import { PrivacyPolicy } from './pages/privacyPolicy/PrivacyPolicy.jsx'
import { Imprint } from './pages/imprint/Imprint.jsx'

export const App = () => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/imprint" element={<Imprint />} />
            </Routes>
            <Footer />
        </>
    )
}

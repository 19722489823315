import styled from 'styled-components'

export const StyledWrapWithBurger = styled.div`
    @media (max-width: 1024px) {
        position: fixed;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        z-index: 200;
        background: rgba(0, 0, 0, 0.8);
        border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    }
    @media (max-width: 575px) {
        padding: 0px 0 10px;
    }
`
export const Menu = styled.div``
export const Hamburger = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        margin-left: 30px;
        z-index: 300;
        cursor: pointer;
    }

    ${Menu} {
        width: 26px;
        height: 2px;
        margin: auto;
        margin-bottom: 5px;
        border-radius: 3px;
        background: #ffffff;
        transition: all 0.5s ease-in-out;

        &:nth-child(1) {
            margin-top: 5px;
            transform: ${({ openBurger }) =>
                openBurger
                    ? 'rotate(0)'
                    : 'rotate(45deg)  translate(10px, 10px)'};
        }

        &:nth-child(2) {
            opacity: 0;
            transform: ${({ openBurger }) =>
                openBurger ? 'translateX(0)' : 'translateX(100%)'};
            opacity: ${({ openBurger }) => (openBurger ? 1 : 0)};
        }

        &:nth-child(3) {
            transform: ${({ openBurger }) =>
                openBurger ? 'rotate(0)' : 'rotate(-45deg)'};
        }
    }
`
export const WrapWithBurger = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: block;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 50px;
    }
`
export const Wrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    max-width: 1440px;
    width: 100vw;
    z-index: 100;
    padding: 10px 50px;
    background: #000000;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    @media (max-width: 1024px) {
        height: 110vh;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: flex-end;
        position: fixed;
        top: 0;
        left: 0;
        padding-top: 60px;
        transform: ${({ openBurger }) =>
            openBurger ? 'translateX(100%)' : 'translateX(0)'};
        transition: transform 0.3s ease-in-out;
    }
    @media (max-width: 575px) {
    }
`
export const Logo = styled.img`
    @media (max-width: 1024px) {
        width: 150px;
    }
`
export const Title = styled.li`
    margin-right: 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.05em;
    color: ${({ props }) => (props ? '#FFFFFF' : '#d2d2d2')};
    text-transform: uppercase;
    cursor: pointer;
    a {
        color: #d2d2d2;
        :hover {
            color: #ffffff;
        }
    }
    :hover {
        color: #ffffff;
        list-style: ${({ props }) => (props ? 'none' : 'disc')};
    }
    @media (max-width: 1024px) {
        margin: 2px 0;
        margin-right: 0;
    }
`
export const WrapPages = styled.ul`
    display: flex;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const WrapPagesTablet = styled.ul`
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
    @media (max-width: 575px) {
        display: none;
    }
`
export const WrapPagesMobile = styled.ul`
    display: none;
    @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`
export const WrapButtons = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
        display: none;
    }
`
export const DropDownContent = styled.div`
    display: none;
    position: fixed;
    top: 28px;
    width: 35px;
    height: 90px;
    background: black;
    border: 0.5px solid #ffffff;
    border-radius: 20px;
    button {
        display: block;
        margin: 0 auto;
        padding: 9px 0 3px;
        color: #ffffff;
        opacity: 0.8;
        background: transparent;
        border: none;
        transition: 0.5s;
        cursor: pointer;
        text-transform: uppercase;
        :hover {
            opacity: 1;
            transform: scale(1.1);
        }
    }
`
export const DropDown = styled.div`
    position: relative;
    display: inline-block;
    margin-right: 20px;
    transition: all 0.5s ease;
    &:hover ${DropDownContent} {
        display: block;
    }
`
export const ButtonLanguage = styled.button`
    width: 35px;
    height: 35px;
    color: #ffffff;
    background: transparent;
    border: 0.5px solid #ffffff;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    cursor: pointer;
    @media (max-width: 1024px) {
        border: none;
        margin-right: 10px;
        border-radius: ${({ isActive }) => (isActive ? '50%' : 'none')};
        border: ${({ isActive }) =>
            isActive ? '0.5px solid #ffffff' : 'none'};
    }
`
export const ButtonStart = styled.button`
    display: block;
    width: 185px;
    height: 46px;
    margin-top: ${({ props }) => (props ? '50px' : '0')};
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    background: #ffffff;
    border-radius: 30px;
    border: 1px solid #ffffff;
    color: #000000;
    transition: 1s;
    cursor: pointer;
    span {
        padding-left: 10px;
    }
    :hover {
        background: #000000;
        color: #ffffff;
        transform: scale(1.1);
    }
    @media (max-width: 1024px) {
        display: none;
    }
`
export const ImgLine = styled.img`
    width: 150px;
`
export const WrapperLinks = styled.ul`
    padding: 0;
    text-align: center;
    a {
        text-decoration: none;
        color: #d2d2d2;
    }
`
export const WrapLink = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`
export const WrapLang = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        margin: 50px 0 30px;
    }
`

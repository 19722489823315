import { useState, useEffect } from 'react'
import { images } from '../../styles/images'
import {
    CarouselContainer,
    Section,
    WrapImg,
    Gradient,
    Img,
    WrapButtons,
    WrapDots,
    Dot,
    ButtonNext,
    ButtonPrev,
    WrapDesc,
    Description,
    SubTitle,
} from '../carouselTablet/Carousel.styles'
import '../carouselTablet/Carousel.css'

const cards = [
    {
        id: 1,
        image: images.team_peter_tabl,
        title: 'Peter Hanschitz',
        desc: 'FOUNDER',
    },
    {
        id: 2,
        image: images.team_stefan,
        title: 'Stefan Höfinger',
        desc: 'CO-FOUNDER',
    },
    {
        id: 3,
        image: images.team_business_tabl,
        title: 'PAUL WAGNER',
        desc: 'Software Development',
    },
]

export const CarouselTeamTablet = () => {
    const [currentIndex, setCurrentIndex] = useState(0)
    useEffect(() => {
        const lastIndex = cards.length - 1
        if (currentIndex < 0) {
            setCurrentIndex(lastIndex)
        }
        if (currentIndex > lastIndex) {
            setCurrentIndex(0)
        }
    }, [currentIndex])

    useEffect(() => {
        let slider = setInterval(
            () => setCurrentIndex((prevState) => prevState + 1),
            5000
        )
        return () => {
            clearInterval(slider)
        }
    }, [currentIndex])

    const Dots = []
    for (let i = 0; i < 3; i++) {
        Dots.push(<Dot key={i} itemNumber={i} active={i == currentIndex} />)
    }

    return (
        <CarouselContainer>
            <Section>
                {cards.map((card, cardIndex) => {
                    const { id, image, title, desc } = card

                    let position = 'nextSlide'
                    if (cardIndex === currentIndex) {
                        position = 'activeSlide'
                    }
                    if (
                        cardIndex === currentIndex - 1 ||
                        (currentIndex === 0 && cardIndex === cards.length - 1)
                    ) {
                        position = 'lastSlide'
                    }

                    return (
                        <article className={position} key={id}>
                            <WrapImg>
                                <Gradient></Gradient>
                                <Img src={image} />
                            </WrapImg>
                            <WrapDesc props="props">
                                <SubTitle>{title}</SubTitle>
                                <Description>{desc}</Description>
                            </WrapDesc>
                        </article>
                    )
                })}
                <WrapButtons>
                    <ButtonPrev
                        onClick={() =>
                            setCurrentIndex((prevState) => prevState - 1)
                        }
                    ></ButtonPrev>
                    <WrapDots>{Dots}</WrapDots>
                    <ButtonNext
                        onClick={() =>
                            setCurrentIndex((prevState) => prevState + 1)
                        }
                    ></ButtonNext>
                </WrapButtons>
            </Section>
        </CarouselContainer>
    )
}

import logo from '../images/logo.svg'
import btn_arrow from '../images/btn_arrow.svg'
import runtext from '../images/run_text.svg'
import mainImg from '../images/main_img.jpg'
import about_1 from '../images/about_1.jpg'
import about_2 from '../images/about_2.jpg'
import about_3 from '../images/about_3.jpg'
import hexagons from '../images/hexagons.png'
import team_peter from '../images/team_peter.png'
import team_peter_tabl from '../images/team_peter_tabl.svg'
import team_business_tabl from '../images/team_business_tabl.svg'
import team_peter_mobile from '../images/team_peter_mobile.svg'
import team_stefan_mobile from '../images/team_stefan_mobile.png'
import team_business_mobile from '../images/team_business_mobile.svg'
import team_stefan from '../images/team_stefan.png'
import team_business from '../images/team_business.png'
import map from '../images/map.svg'
import map_tablet from '../images/map_tablet.svg'
import map_mobile from '../images/map_mobile.svg'
import arrow_down from '../images/arrow.png'
import build from '../images/build.svg'
import define from '../images/define.svg'
import iterate from '../images/iterate.svg'
import launch from '../images/launch.svg'
import measure from '../images/measure.svg'
import plan from '../images/plan.svg'
import dot from '../images/dot.png'
import switzerland from '../images/switzerland.svg'
import austria from '../images/austria.svg'
import line from '../images/line.png'
import hexagons_tablet from '../images/hexagons_tablet.svg'
import hexagons_mobile from '../images/hexagons_mobile.svg'
import arrow_prev from '../images/arrow_prev.png'
import arrow_right from '../images/arrow_right.png'

export const images = {
    logo: logo,
    btn_arrow: btn_arrow,
    runtext: runtext,
    mainImg: mainImg,
    about_1: about_1,
    about_2: about_2,
    about_3: about_3,
    hexagons: hexagons,
    team_peter: team_peter,
    team_stefan: team_stefan,
    team_business: team_business,
    map: map,
    arrow_down: arrow_down,
    build: build,
    define: define,
    iterate: iterate,
    launch: launch,
    measure: measure,
    plan: plan,
    dot: dot,
    switzerland: switzerland,
    austria: austria,
    line: line,
    hexagons_tablet: hexagons_tablet,
    arrow_prev: arrow_prev,
    arrow_right: arrow_right,
    team_peter_tabl: team_peter_tabl,
    team_business_tabl: team_business_tabl,
    hexagons_mobile: hexagons_mobile,
    map_tablet: map_tablet,
    map_mobile: map_mobile,
    team_peter_mobile: team_peter_mobile,
    team_stefan_mobile: team_stefan_mobile,
    team_business_mobile: team_business_mobile,
}
